<template>
  <main class="main">
    <nav class="breadcrumb-nav border-0 mb-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">{{ $t("payment_cancel") }}</li>
        </ol>
      </div>
    </nav>

    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb-2 mb-lg-0">
            <div class="order-confirmation">
              <h2 class="title text-primary mb-1">
                {{ $t("payment_cancel") }}
              </h2>
              <p class="text-dark mt-2">
                {{ $t("your_order_number_is", { orderNo: transactionId }) }}
              </p>
              <p class="text-dark mb-2">{{ $t("cancel_order") }}</p>
              <router-link to="/">
                <font-awesome-icon icon="fa-solid fa-angle-left" />
                {{ $t("continue_shopping") }}</router-link
              >
              <!-- <p class="mt-1">
                                {{ $t('email_address') }}
                                <b-link :href="'mailto:' + user.email">{{ user.email }}</b-link>
                            </p> -->

              <!-- <router-link to="/account/">
                                <button class="order-confirmation-button mt-2">
                                    {{ $t('account_create') }}
                                </button>
                            </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// import { getOrderInfoFromHash } from "@storefront/core/data-resolver/cart";
import { loadCart } from "@storefront/core/data-resolver/cart";
import { Logger } from "@storefront/core/lib/logger";
// import config from "@storefront/core/lib/utils/config";

export default {
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    // success_page() {
    //     return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
    //         "success_page"
    //     );
    // },
  },
  async mounted() {
    await loadCart();

    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    Logger.debug("router", "mounted", this.transactionId)();
    // if (this.transactionId != null) {
    //     const hashId = localStorage.getItem("orderHash");
    //     const order = await getOrderInfoFromHash(hashId);
    //     Logger.debug("order", "thank you", order)();
    //     if (order != false) {
    //         this.email = order.email;
    //         if (this.$gtm.enabled() == true) {
    //             const items = [];
    //             order.items.forEach((element) => {
    //                 let item = {};
    //                 item.id = element.sku;
    //                 item.name = element.name;
    //                 item.price = element.product_sale_price;
    //                 item.quantity = element.quantity_ordered;
    //                 items.push(item);
    //             });
    //             if (this.$gtm.enabled()) {
    //                 //Logger.debug("mounted order", "paymentsuccess", order)();
    //                 window.dataLayer.push({
    //                     event: "purchase",
    //                     ecommerce: {
    //                         currencyCode: "EUR",
    //                         purchase: {
    //                             actionField: {
    //                                 id: this.transactionId,
    //                                 affiliation: config.app_name,
    //                                 revenue: order.total.subtotal.value,
    //                                 tax: order.total.total_tax.value,
    //                                 shipping: order.total.total_shipping.value,
    //                             },
    //                             products: items,
    //                         },
    //                     },
    //                 });
    //             }
    //         }
    //     }
    // }
  },
  data: () => ({
    email: "",
  }),

  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("payment_cancel") },
        { name: "keywords", content: this.$t("payment_cancel") },
        { name: "description", content: this.$t("payment_cancel") },
      ],
      title: this.$t("payment_cancel"),
    };
  },
};
</script>
